<script lang="ts">
   
    import { onDestroy, afterUpdate } from 'svelte';
    import type { ModalLightbox } from '@components/Modals/types'
 
    export let data: ModalLightbox
 
    let images = data.images || []
 
    let index = images.findIndex( image =>  image.Active )
    
    $: currentImage = images[index]
 
 
    function onkeydown(e) {
       let keyCode = e.keyCode
       // RightArrow
       if (keyCode == 39) 
          next(e)
       // LeftArrow
       if (keyCode == 37) 
          prev(e)
       // Esc
       if (keyCode == 27) 
       redirectToImagePage()
    }
 
    function next(e) {
       index = (index + 1) % images.length;
       updateTitle()
    }
    
    function prev(e) {
       index = (images.length + index - 1) % images.length;
       updateTitle()
    }
 
    function updateTitle() {
       let title_elem = document.getElementsByClassName("modal-title")[0]
 
       let title = images[index].ShortDescription 
       
       if(!title) {
          title = "No Title"
       }
       
       title_elem.textContent = title
    }
 
    function redirectToImagePage() {
       if(images.length && location != images[index].ModLink) {
          location.replace(images[index].ModLink)
       }
    }
 
    onDestroy(
       () => {
          redirectToImagePage()
       }
    )
    
 </script>
 
 <svelte:window on:keydown|preventDefault={onkeydown} />
 
 {#if currentImage}
    
    <div class="lightbox__body">   
    
       <div class="lightbox__img col-12 col-md-8">
       
          {#if currentImage.Url}
             <img src="{currentImage.Url}" alt="{currentImage.ShortDescription}"/>
          {/if}
       
       </div>
    
       <div class="lightbox__description col-12 col-md-4">
          
          {#if currentImage.Description}
             <p>{@html currentImage.Description}</p>
          {/if}
 
       </div>
 
       <div class="lightbox__controls">   
 
          {#if images.length > 1}
             <a class="prev" href="#prev" on:click|preventDefault={prev}>
                <svg viewBox="0 0 24 24">
                   <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                </svg>
             </a>
 
             <a class="next" href="#next" on:click|preventDefault={next}>
                <svg viewBox="0 0 24 24">
                   <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                </svg>
             </a>
          {/if}
 
       </div>
 
    </div>
 
 {/if}