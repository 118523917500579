<script lang="ts">
import type { Events, ModalData } from './types'

import ModalActionList       from './Types/ModalActionList.svelte'
import ModalComponentContent from './Types/ModalComponentContent.svelte'
import ModalConfirm          from './Types/ModalConfirm.svelte'
import ModalElementContent   from './Types/ModalElementContent.svelte'
import ModalFormContent      from './Types/ModalFormContent.svelte'
import ModalHtmlContent      from './Types/ModalHtmlContent.svelte'
import ModalIframeContent    from './Types/ModalIframeContent.svelte';
import ModalTexteditor       from './Types/ModalTexteditor.svelte'
import ModalVideoContent     from './Types/ModalVideoContent.svelte'
import ModalLightbox           from './Types/ModalLightbox.svelte'

export let data: ModalData
export let closeModal
export let events: Events

const componentMap = {
    actionlist: ModalActionList,
    component: ModalComponentContent,
    confirm: ModalConfirm,
    element: ModalElementContent,
    form: ModalFormContent,
    html: ModalHtmlContent,
    iframe: ModalIframeContent,
    texteditor: ModalTexteditor,
    video: ModalVideoContent,
    lightbox: ModalLightbox
}

function close() {
    closeModal(data.id)
}
</script>

<div class="modal-header">
    {#if data.title}
        <h5 class="modal-title">
            {@html data.title}
        </h5>
    {/if}

    <button on:click={close} type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<svelte:component this={componentMap[data.type]} data={data} events={events} on:closemodal={close} />
